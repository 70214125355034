import './Footer.css'
import React from 'react'

export default function Footer() {
    return (
        <div className="footer">
            <p>© {new Date().getFullYear()} Copyright:<a href="/#" > Bentz Ingeniería. </a></p>
            <p>{" "} Diseñado por<a href="https://svringenieria.cl" rel="noopener noreferrer" target="_blank"> SVR Ingeniería</a></p>
        </div>
    )
}
